"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultRankedMetric = void 0;
const defaultRankedMetric = () => {
    return {
        value: 0,
        rank: 0
    };
};
exports.defaultRankedMetric = defaultRankedMetric;
